const nlp = async (message, language = "english") => {

  const url = `https://api.seeandsign.com:81/nlp/v2`;

  const response = await fetch(url, {
    mode: "cors",
    method: "POST",
    cache: "no-cache",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: message,
      bot: "nlpold-en", // "aygaz"
      user : 65 
    }),
  });

  if (response.status !== 200) {
    throw new Error("something went wrong");
  }
  const data = await response.json();
  return data;
};

export { nlp };

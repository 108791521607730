import React, { useState, useRef, useEffect } from "react";
import { store } from "../store/store";
import { connect } from "remx";

function SpeechBuble(props) {
  const messagesEndRef = useRef(null);
  const [showHistory, setShowHistory] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  };

  const showHistoryHandler = () => setShowHistory(!showHistory);

  useEffect(scrollToBottom, [props.text]);
  return (
    <>
     
      <div className="speechb-container" ref={messagesEndRef}>
        {showHistory
          ? props.messages.map((e) => {
              return (
                <div key={`${Math.random() * 1000}`}>
                  <p
                    className="speechb-owner "
                    style={{ textAlign: e.isHuman ? "right" : "left" }}
                  >
                    {e.isHuman ? "Me" : "Mila"}
                  </p>
                  <p
                    className="speechb "
                    style={{ textAlign: e.isHuman ? "right" : "left" }}
                  >
                    {e.message}
                  </p>
                </div>
              );
            })
          : null}
           <button onClick={showHistoryHandler} className="historyButton">
        {!showHistory ? "Chat History" : "Hide"}
      </button>
      </div>
    </>
  );
}

function mapStateToProps(ownProps) {
  return {
    messages: store.getAllMessageList(),
  };
}

export default connect(mapStateToProps)(SpeechBuble);

import * as remx from "remx";

const initialState = {
  messageList: [],
  nlpResponse : "",
};

const state = remx.state(initialState);

const setters = remx.setters({
  addMessageList(message, isHuman) {
    state.messageList.push({ message, isHuman });
  },
  setNlpResponse(nlpText){
    state.nlpResponse = nlpText;
  }
});

const getters = remx.getters({
  getAllMessageList() {
    return state.messageList; 
  },
  getNlpResponse(){
    return state.nlpResponse;
  }
});

export const store = {
  ...setters,
  ...getters,
};

function parseText(text) {
  let lipsMovements = [];
  text.toLowerCase();
  // convert letters to phonemes
  for (var i = 0, len = text.length; i < len; i++) {
    if (text[i] == 'a') {
      lipsMovements.push('a');
    }
    if (text[i] == 'b') {
      lipsMovements.push('m');
    }
    if (text[i] == 'c') {
      lipsMovements.push('s');
    }
    if (text[i] == 'd') {
      lipsMovements.push('s');
    }
    if (text[i] == 'e') {
      lipsMovements.push('e');
    }
    if (text[i] == 'f') {
      lipsMovements.push('f');
    }
    if (text[i] == 'g') {
      lipsMovements.push('s');
    }
    if (text[i] == 'h') {
      lipsMovements.push('t');
    }
    if (text[i] == 'i') {
      lipsMovements.push('a');
    }
    if (text[i] == 'j') {
      lipsMovements.push('s');
    }
    if (text[i] == 'k') {
      lipsMovements.push('k');
    }
    if (text[i] == 'l') {
      lipsMovements.push('l');
    }
    if (text[i] == 'm') {
      lipsMovements.push('m');
    }
    if (text[i] == 'n') {
      lipsMovements.push('s');
    }
    if (text[i] == 'o') {
      lipsMovements.push('o');
    }
    if (text[i] == 'p') {
      lipsMovements.push('m');
    }
    if (text[i] == 'q') {
      lipsMovements.push('u');
    }
    if (text[i] == 'r') {
      lipsMovements.push('r');
    }
    if (text[i] == 's') {
      lipsMovements.push('s');
    }
    if (text[i] == 't') {
      lipsMovements.push('t');
    }
    if (text[i] == 'u') {
      lipsMovements.push('u');
    }
    if (text[i] == 'v') {
      lipsMovements.push('f');
    }
    if (text[i] == 'w') {
      lipsMovements.push('u');
    }
    if (text[i] == 'x') {
      lipsMovements.push('s');
    }
    if (text[i] == 'y') {
      lipsMovements.push('s');
    }
    if (text[i] == 'z') {
      lipsMovements.push('s');
    }
    // if (text[i] == '') {
    //   lipsMovements.push('0');
    // }
    // if (text[i] == '.') {
    //   lipsMovements.push('0');
    // }
    // if (text[i] == ',') {
    //   lipsMovements.push('0');
    // }
    // if(text[i] == " "){
    //   lipsMovements.push('0');
    // }
  }
  lipsMovements.push('0');
  return lipsMovements;
}

export { parseText };




// function parseText(text) {
//   let lipsMovements = [];
//   text.toLowerCase();
//   // convert letters to phonemes
//   for (var i = 0, len = text.length; i < len; i++) {
//     if (text[i] == 'a') { lipsMovements.push("a");}
//     if (text[i] == 'e') { lipsMovements.push("a");}
//     if (text[i] == 'i') { lipsMovements.push("a");}
//     if (text[i] == 'o') { lipsMovements.push("o");}
//     if (text[i] == 'u') { lipsMovements.push("o");}
//     if (text[i] == 'q') { lipsMovements.push("o");}
//     if (text[i] == 'w') { lipsMovements.push("o");}
//     if (text[i] == 'r') { lipsMovements.push("0");}
//     if (text[i] == 't') { lipsMovements.push("0");}
//     if (text[i] == 'g') { lipsMovements.push("m");}
//     if (text[i] == 'h') { lipsMovements.push("0");}
//     if (text[i] == 'j') { lipsMovements.push("o");}
//     if (text[i] == 'x') { lipsMovements.push("0");}
//     if (text[i] == 'y') { lipsMovements.push("0");}
//     if (text[i] == 'd') { lipsMovements.push("0");}
//     if (text[i] == 'k') { lipsMovements.push("0");}
//     if (text[i] == 's') { lipsMovements.push("0");}
//     if (text[i] == 'c') { lipsMovements.push("0");}
//     if (text[i] == 'z') { lipsMovements.push("0");}
//     if (text[i] == 'm') { lipsMovements.push("m");}
//     if (text[i] == 'b') { lipsMovements.push("m");}
//     if (text[i] == 'p') { lipsMovements.push("m");}
//     if (text[i] == 'l') { lipsMovements.push("0");}
//     if (text[i] == 'n') { lipsMovements.push("m");}
//     if (text[i] == 'f') { lipsMovements.push("m");}
//     if (text[i] == 'v') { lipsMovements.push("m");}
//     if (text[i] == '') { lipsMovements.push("0");}
//     if (text[i] == '.') { lipsMovements.push("0");}
//     if (text[i] == ',') { lipsMovements.push("0");}
//     // if (text[i] == '') {
//     //   lipsMovements.push('0');
//     // }
//     // if (text[i] == '.') {
//     //   lipsMovements.push('0');
//     // }
//     // if (text[i] == ',') {
//     //   lipsMovements.push('0');
//     // }
//     // if(text[i] == " "){
//     //   lipsMovements.push('0');
//     // }
//   }
//   lipsMovements.push('0');
//   return lipsMovements;
// }

// export { parseText };
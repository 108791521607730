import React, { useState, useEffect } from "react";
import DigitalHuman from "../components/DigitalHuman";
import Form from "../components/Form";
import Logo from "../components/Logo";
import SpeechBuble from "../components/SpeechBuble";

function HomeScreen(props) {
  const [nlpText, setNlpText] = useState("");

  const handleSubmit = (text) => {
    setNlpText(text);
  };

  return (
    <div className="App">
      <DigitalHuman id="digitalhuman" />
      <Logo />
      <Form submitHandler={handleSubmit} />
      <SpeechBuble text={nlpText} />
    </div>
  );
}

export default HomeScreen;

import React, { useState, useEffect, useRef } from "react";
import { nlp } from "../services/NLP";
import createSpeechServicesPonyfill from "web-speech-cognitive-services";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaMicrophoneAlt } from "react-icons/fa";
import { FaMicrophoneAltSlash } from "react-icons/fa";

import { store } from "../store/store";

const SUBSCRIPTION_KEY = "3a5a89a72bbc4f9eba0d42a8ba80d096 ";
const REGION = "westus2";
const TOKEN_ENDPOINT = `https://${REGION}.api.cognitive.microsoft.com/sts/v1.0/issuetoken`;

function Form(props) {
  const send = useRef();

  const [done, setDone] = useState(false);

  const { listening, finalTranscript, resetTranscript } =
    useSpeechRecognition();

  const [userInput, setUserInput] = useState("");

  const [loadingSpeechRecognition, setLoadingSpeechRecognition] =
    useState(true);

  useEffect(() => {
    const loadSpeechRecognition = async () => {
      const response = await fetch(TOKEN_ENDPOINT, {
        method: "POST",
        headers: { "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY },
      });
      const authorizationToken = await response.text();
      const { SpeechRecognition: AzureSpeechRecognition } =
        await createSpeechServicesPonyfill({
          credentials: {
            region: REGION,
            authorizationToken,
          },
        });
      SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
      setLoadingSpeechRecognition(false);
    };
    loadSpeechRecognition();
  }, []);

  useEffect(() => {
    if (done && userInput !== "") {
      setTimeout(() => {
        send.current.click();
      }, 100);
    }
    setDone(false);
  }, [done]);

  useEffect(() => {
    setUserInput(finalTranscript);
    setDone(true);
  }, [finalTranscript]);

  //en-US
  //tr-TR

  const handleTalk = (event) => {
    event.preventDefault();
    SpeechRecognition.startListening({
      continuous: false,
      language: "en-US",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    store.addMessageList(userInput, true);
    nlp(userInput).then((data) => {
      console.log(data.responses[1].text);
      store.addMessageList(data.responses[1].text, false);
      store.setNlpResponse(data.responses[1].text);
      props.submitHandler(data.responses[1].text);
    });
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  return (
    <div className="form-container">
      <form className="userInputForm" onSubmit={handleSubmit}>
        <input
          className="inputBox"
          type="text"
          placeholder="Enter a message"
          onChange={handleInputChange}
          value={userInput}
        />
        <button ref={send} className="formButton" style={{ marginRight: 10 }}>
          Send
        </button>

        <button type="button" className="formButton" onClick={handleTalk}>
          <FaMicrophoneAlt size="1.5em" className={listening && "mic"} />
        </button>
      </form>
    </div>
  );
}

export default Form;

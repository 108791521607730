import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { createTween } from './Utils/CreateTween';
import { parseText } from './Utils/parseText';
import * as TWEEN from '@tweenjs/tween.js';
import { DoubleSide } from "three"

import { store } from "../../store/store";
import { connect } from 'remx';

import * as sdk from "microsoft-cognitiveservices-speech-sdk";
const SUBSCRIPTION_KEY = "3a5a89a72bbc4f9eba0d42a8ba80d096";
const REGION = "westus2";
const TOKEN_ENDPOINT = "https://westus2.api.cognitive.microsoft.com/sts/v1.0/issuetoken";

const speechConfig = sdk.SpeechConfig.fromSubscription(SUBSCRIPTION_KEY, REGION);
const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
const synthesizer = new sdk.SpeechSynthesizer(speechConfig, undefined);

function Model(props) {
  
    const [idlePlay, setIdlePlay] = useState(true);
    const [talkPlay, setTalkPlay] = useState(false);

    const { camera } = useThree();

    useFrame(() => {
      nodes.Sag_goz.lookAt(camera.position.x - 0.08,camera.position.y,  camera.position.z )
      nodes.Sol_goz.lookAt( camera.position.x + 0.08,camera.position.y, camera.position.z )
      nodes.Head.lookAt(camera.position)
    })

    const group = useRef()
    const { nodes, materials, animations } = useGLTF('/aiko_mixamo_v14_yeniAni.glb')
    const { actions } = useAnimations(animations, group)

    const meshForLips = nodes.body_rigsiz_1;
    const influences = meshForLips.morphTargetInfluences;

    useEffect(()=>{
        console.log(nodes)
    },[])

    useFrame((state) => {
      TWEEN.update();
    });

    // const idleMap = {
    //   1: 'idle_1',
    //   2: 'idle_2',
    //   3: 'idle_3',
    // };
    // const talkMap = {
    //   1: 'talk_1',
    //   2: 'talk_2',
    //   3: 'talk_2',
    // };

    const idleMap = {
        1: 'idle',
        2: 'idle',
        3: 'chat_stand_idle',
        4: 'chat_leaning_idle',
      };
      const talkMap = {
        1: 'standing_chat',
        2: 'stand_talk',
        3: 'chat_stand',
        4: 'chat_leaning',
        5: 'sit_to_stand',
      };

    const [randomAnimationNumber, setRandomAnimationNumber] = useState()
    //Animations
    useLayoutEffect(() => {
      console.log(randomAnimationNumber)
      actions[idleMap[randomAnimationNumber || '1']].clampWhenFinished = true;

      if (idlePlay && !talkPlay) {
        actions[idleMap[randomAnimationNumber || '1']]
          .reset()
          .setEffectiveTimeScale(1)
          .setEffectiveWeight(1)
          .fadeIn(1)
          .play();
      } else if (!idlePlay && talkPlay) {
        actions[idleMap[randomAnimationNumber || '1']].crossFadeTo(
          actions[talkMap[randomAnimationNumber || '1']],
          1,
          true
        );
        actions[talkMap[randomAnimationNumber || '1']]
          .reset()
          .setEffectiveTimeScale(1)
          .setEffectiveWeight(1)
          .fadeIn(1)
          .play();
      }

      return () => {
        actions[talkMap[randomAnimationNumber || '1']].crossFadeTo(
          actions[idleMap[randomAnimationNumber || '1']],
          1,
          true
        );
      };
    
    }, [idlePlay]);

    //Eye Blinking
    const tweenLeftEye = createTween(meshForLips, 26, 0, 1, 50);
    tweenLeftEye.easing(TWEEN.Easing.Elastic.InOut);

    const tweenRightEye = createTween(meshForLips, 27, 0, 1, 50);
    tweenRightEye.easing(TWEEN.Easing.Elastic.InOut);

    const tweenLeftEyeReset = createTween(meshForLips, 26, 1, 0, 100);
    tweenLeftEyeReset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenRightEyeReset = createTween(meshForLips, 27, 1, 0, 100);
    tweenRightEyeReset.easing(TWEEN.Easing.Elastic.InOut);

    let randomNumberForBlink = 1;

    useEffect(()=>{
      setInterval(function generateRandomEyeBlink() {
        randomNumberForBlink = Math.random();
        if (randomNumberForBlink < 0.14) {
          tweenLeftEye.start().onComplete(() => {
            tweenLeftEyeReset.start();
          });
          tweenRightEye.start().onComplete(() => {
            tweenRightEyeReset.start();
          });
        }
      }, 500);
    },[])

    //create morphs
    const tweenA = createTween(meshForLips, 16, 0, 1, 50);
    tweenA.easing(TWEEN.Easing.Elastic.InOut);

    const tweenE = createTween(meshForLips, 15, 0, 1, 50);
    tweenE.easing(TWEEN.Easing.Elastic.InOut);

    const tweenR = createTween(meshForLips, 14, 0, 1, 50);
    tweenR.easing(TWEEN.Easing.Elastic.InOut);

    const tweenF = createTween(meshForLips, 13, 0, 1, 50);
    tweenF.easing(TWEEN.Easing.Elastic.InOut);

    const tweenK = createTween(meshForLips, 1, 0, 1, 50);
    tweenK.easing(TWEEN.Easing.Elastic.InOut);

    const tweenL = createTween(meshForLips, 12, 0, 1, 50);
    tweenL.easing(TWEEN.Easing.Elastic.InOut);

    const tweenM = createTween(meshForLips, 11, 0, 1, 50);
    tweenM.easing(TWEEN.Easing.Elastic.InOut);

    const tweenO = createTween(meshForLips, 10, 0, 1, 50);
    tweenO.easing(TWEEN.Easing.Elastic.InOut);

    const tweenS = createTween(meshForLips, 9, 0, 1, 50);
    tweenS.easing(TWEEN.Easing.Elastic.InOut);

    const tweenT = createTween(meshForLips, 8, 0, 1, 50);
    tweenT.easing(TWEEN.Easing.Elastic.InOut);

    const tweenU = createTween(meshForLips, 0, 0, 1, 50);
    tweenU.easing(TWEEN.Easing.Elastic.InOut);

    const tweenAngry = createTween(meshForLips, 4, 0, 1, 500 );
    tweenAngry.easing(TWEEN.Easing.Elastic.InOut);

    const tweenSad = createTween(meshForLips, 5, 0, 1, 500);
    tweenSad.easing(TWEEN.Easing.Elastic.InOut);

    const tweenSmileFull = createTween(meshForLips, 6, 0, 0.5, 500);
    tweenSmileFull.easing(TWEEN.Easing.Elastic.InOut);

    //remove morphs
    const tweenA_Reset = createTween(meshForLips, 16, 1, 0, 50);
    tweenA_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenE_Reset = createTween(meshForLips, 15, 1, 0, 50);
    tweenE_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenR_Reset = createTween(meshForLips, 14, 1, 0, 50);
    tweenR_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenF_Reset = createTween(meshForLips, 13, 1, 0, 50);
    tweenF_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenK_Reset = createTween(meshForLips, 1, 1, 0, 50);
    tweenK_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenL_Reset = createTween(meshForLips, 12, 1, 0, 50);
    tweenL_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenM_Reset = createTween(meshForLips, 11, 1, 0, 50);
    tweenM_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenO_Reset = createTween(meshForLips, 10, 1, 0, 50);
    tweenO_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenS_Reset = createTween(meshForLips, 9, 1, 0, 50);
    tweenS_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenT_Reset = createTween(meshForLips, 8, 1, 0, 50);
    tweenT_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenU_Reset = createTween(meshForLips, 0, 1, 0, 50);
    tweenU_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenAngry_Reset = createTween(meshForLips, 4, 1, 0, 1000 );
    tweenAngry_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenSad_Reset = createTween(meshForLips, 5, 1, 0, 1500);
    tweenSad_Reset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenSmileFull_Reset = createTween(meshForLips, 6, 0.5, 0, 1500);
    tweenSmileFull_Reset.easing(TWEEN.Easing.Elastic.InOut);

    function mouth_A() {
      if (influences[16] === 0) {
        tweenA.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_E() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 0) {
        tweenE.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_R() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 0) {
        tweenR.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_F() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 0) {
        tweenF.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_K() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 0) {
        tweenK.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_L() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 0) {
        tweenL.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_M() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 0) {
        tweenM.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_O() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 0) {
        tweenO.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_S() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 0) {
        tweenS.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_T() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[0] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 0) {
        tweenT.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function mouth_U() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 0) {
        tweenU.start();
      }
    }

    function mouth_Reset() {
      if (influences[16] === 1) {
        tweenA_Reset.start();
      }
      if (influences[15] === 1) {
        tweenE_Reset.start();
      }
      if (influences[14] === 1) {
        tweenR_Reset.start();
      }
      if (influences[13] === 1) {
        tweenF_Reset.start();
      }
      if (influences[1] === 1) {
        tweenK_Reset.start();
      }
      if (influences[12] === 1) {
        tweenL_Reset.start();
      }
      if (influences[11] === 1) {
        tweenM_Reset.start();
      }
      if (influences[10] === 1) {
        tweenO_Reset.start();
      }
      if (influences[9] === 1) {
        tweenS_Reset.start();
      }
      if (influences[8] === 1) {
        tweenT_Reset.start();
      }
      if (influences[0] === 1) {
        tweenU_Reset.start();
      }
    }

    function speak(text) {
      const rate = 1;
      let lipsMovements = parseText(text);
      console.log("movements: ", lipsMovements.length );
      for (let i = 0; i < lipsMovements.length; i++) {
        // for each phoneme with pause
        (function (i) {
          setTimeout(function () {
            if (lipsMovements[i] == 'a') {
              mouth_A();
            } else if (lipsMovements[i] == 'e') {
              mouth_E();
            } else if (lipsMovements[i] == 'r') {
              mouth_R();
            } else if (lipsMovements[i] == 'f') {
              mouth_F();
            } else if (lipsMovements[i] == 'k') {
              mouth_K();
            } else if (lipsMovements[i] == 'l') {
              mouth_L();
            } else if (lipsMovements[i] == 'm') {
              mouth_M();
            } else if (lipsMovements[i] == 'o') {
              mouth_O();
            } else if (lipsMovements[i] == 's') {
              mouth_S();
            } else if (lipsMovements[i] == 't') {
              mouth_T();
            } else if (lipsMovements[i] == 'u') {
              mouth_U();
            } else if (lipsMovements[i] == '0') {
              mouth_Reset();
            }
            
          }, (66 / rate) * i);
        })(i);
      }
    }

    useEffect(()=>{
      if(props.nlpResponse.length){
        let gelenNLPArray = props.nlpResponse.toLowerCase().split('');
        let gidecekNLPArray = [];

        for (const element of gelenNLPArray) {
          if (element !== ':' && element !== ')' && element !== '.' && element !== '"' && element !== '!' && element !== ',' && element !== '?' && element !== "'" && element !== "-") {
            gidecekNLPArray.push(element);
          }
        }
      
        let gidecekNLPText = gidecekNLPArray.join('');
        //console.log("gidecek", gidecekNLPText);

        if(gidecekNLPText.includes("polite")){
          tweenAngry.start().onComplete(()=>{
            tweenAngry_Reset.start()
          })
        }


        if(gidecekNLPText.includes("sorry")){
          tweenSad.start().onComplete(()=>{
            tweenSad_Reset.start()
          })
        }

        if(gidecekNLPText.includes("üzüldüm")){
          tweenSad.start().onComplete(()=>{
            tweenSad_Reset.start()
          })
        }

        if(gidecekNLPText.includes("thank")){
          tweenSmileFull.start().onComplete(()=>{
            tweenSmileFull_Reset.start()
          })
        }

        if(gidecekNLPText.includes("teşekkür")){
          tweenSmileFull.start().onComplete(()=>{
            tweenSmileFull_Reset.start()
          })
        }

        //US English Female
        //UK English Female
        //Turkish Female
        //window.responsiveVoice.enableEstimationTimeout = false;
        //en-US-AriaNeural
        //en-US

        const xmlText = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                          xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="tr-TR">
                            <voice name="	tr-TR-EmelNeural" >
                              <mstts:express-as style="cheerful">
                                ${gidecekNLPText}
                              </mstts:express-as>
                            </voice>
                        </speak>`

        synthesizer.speakSsmlAsync(xmlText)

        let visemeDataArray = [];
        synthesizer.visemeReceived = function (s, e) {
          console.log("(Viseme), Audio offset: " + e.audioOffset / 10000 + "ms. Viseme ID: " + e.visemeId);
          visemeDataArray.push(e.audioOffset / 10000)
        }

        synthesizer.synthesisStarted = () => {
          console.log(gidecekNLPText);
          // setTimeout(() => {
          //   setIdlePlay(false);
          //   setTalkPlay(true);
          //   speak(gidecekNLPText)
          // }, 200);
        }

      synthesizer.synthesisCompleted = function (s, e) {
        console.log(visemeDataArray);
        const talkingAnimationLenght = Math.max(...visemeDataArray)
        console.log(talkingAnimationLenght);
        if(visemeDataArray){
          setTimeout(() => {
            setRandomAnimationNumber(Math.floor(Math.random() * 4) + 1)
            console.log("animasyon bitimi");
            setIdlePlay(true);
            setTalkPlay(false);
          },talkingAnimationLenght);
        }
        console.log("sentezleme bittiiiiiiiiiiii");
        setIdlePlay(false);
        setTalkPlay(true);
        speak(gidecekNLPText)
    };
    
      synthesizer.wordBoundary = function (s, e) {
        //console.log("(WordBoundary), Text: " + e.text + ", Audio offset: " + e.audioOffset / 10000 + "ms.");
      };

        // window.responsiveVoice.speak(gidecekNLPText, 'UK English Female', {
        //   pitch: 1.15,
        //   rate: 1,
        //   onstart: () => {
        //     console.log("speak start");
        //     setIdlePlay(false);
        //     // setIdlePlay(()=>{
        //     //   return false
        //     // })
        //     setTalkPlay(true);
        //     // setTalkPlay(()=>{
        //     //   return true
        //     // })
        //     speak(gidecekNLPText)
        //     //setTimeout(()=>{speak(gidecekNLPText)},200)
            
        //   },
        //   onend: () => {
        //     setRandomAnimationNumber(Math.floor(Math.random() * 3) + 1)
        //     setTalkPlay(false);
        //     // setTalkPlay(()=>{
        //     //   return false
        //     // })
        //     setIdlePlay(true);
        //     // setIdlePlay(()=>{
        //     //   return true
        //     // })
        //   },
        // });
      }
      
    },[props.nlpResponse])

  
    //[-0.05, -1.57, 4.1]
    //rot [0.08, 0, 0]
    return (
      <group ref={group} {...props} dispose={null} position={[-0.05, -1.57, 3.8]}>
      <primitive object={nodes.Hips} />
      <skinnedMesh
        geometry={nodes.karakter_GRP_1.geometry}
        material={materials.shoe}
        skeleton={nodes.karakter_GRP_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.karakter_GRP_2.geometry}
        material={materials.Jacket_PBR}
        skeleton={nodes.karakter_GRP_2.skeleton}
      />
      <skinnedMesh
        geometry={nodes.karakter_GRP_3.geometry}
        material={materials.Hair_PBRR}
        material-side={DoubleSide}
        skeleton={nodes.karakter_GRP_3.skeleton}
      />
      <skinnedMesh
        geometry={nodes.karakter_GRP_4.geometry}
        material={materials.short_PBR}
        skeleton={nodes.karakter_GRP_4.skeleton}
      />
      <skinnedMesh
        name="body_rigsiz_1"
        geometry={nodes.body_rigsiz_1.geometry}
        material={materials.Yuz_PBRR}
        skeleton={nodes.body_rigsiz_1.skeleton}
        morphTargetDictionary={nodes.body_rigsiz_1.morphTargetDictionary}
        morphTargetInfluences={nodes.body_rigsiz_1.morphTargetInfluences}
      />
      <skinnedMesh
        name="body_rigsiz_2"
        geometry={nodes.body_rigsiz_2.geometry}
        material={materials.gozler_PBR}
        skeleton={nodes.body_rigsiz_2.skeleton}
        morphTargetDictionary={nodes.body_rigsiz_2.morphTargetDictionary}
        morphTargetInfluences={nodes.body_rigsiz_2.morphTargetInfluences}
      />
      <skinnedMesh
        name="body_rigsiz_3"
        geometry={nodes.body_rigsiz_3.geometry}
        material={materials.Vucud_PBRR}
        skeleton={nodes.body_rigsiz_3.skeleton}
        morphTargetDictionary={nodes.body_rigsiz_3.morphTargetDictionary}
        morphTargetInfluences={nodes.body_rigsiz_3.morphTargetInfluences}
      />
    </group>
  )
}
useGLTF.preload('/aiko_mixamo_v14_yeniAni.glb')

function mapStateToProps(ownProps) {
  return {
    nlpResponse: store.getNlpResponse(),
  };
}

export default connect(mapStateToProps)(Model);

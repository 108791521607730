import './DigitalHuman.css';
import React, { Suspense, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls, Loader } from '@react-three/drei';
import { ReinhardToneMapping, sRGBEncoding, ACESFilmicToneMapping } from 'three';
import Model2 from './3D_World/Model2';
//import VisemeModel from './3D_World/VisemeModel';
//import Bacaksiz from './3D_World/Bacaksiz';
import LowPolly from './3D_World/LowPolly';

function DigitalHuman(props) {



  const loadingContainerStyles = {
    background : "black",
  }
  const loadingInnerStyles = {
    width : "300px",
    background : "black"
  }
  const loadingBarStyles = {
    height: 20,
    background: 'white',
  };
  const loadingDataStyles = {
    display : "none"
  }

  //[0,0.08,5]
  //[4.56199520444544,0.05,2.0479328646683035]
  return (
    <div id='canvas-container'>
      <Canvas
        onCreated={({ gl }) => {
          gl.physicallyCorrectLights = true;
          gl.toneMapping = ReinhardToneMapping;
          gl.toneMappingExposure = 0.5;
          gl.outputEncoding = sRGBEncoding;
          gl.antialias = true;
          gl.alpha = true;
        }}
        camera={{ position: [0,0,5] }}
      >
        <Suspense fallback={null}>
          <LowPolly/>
          <Environment
          background={true}
          preset={"apartment"}
        />
          
        </Suspense>
        
      </Canvas>
      <Loader barStyles={loadingBarStyles}
      containerStyles={loadingContainerStyles} 
      innerStyles={loadingInnerStyles}
      dataStyles={loadingDataStyles}/>
    </div>
  );
}

export default DigitalHuman;

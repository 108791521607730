import * as TWEEN from '@tweenjs/tween.js';

function createTween(model, whichMorph, startValue, endValue, duration) {
  const rate = 1;
  return new TWEEN.Tween({ val: startValue })
    .to({ val: endValue }, duration * rate)
    .onUpdate(function (value) {
      model.morphTargetInfluences[whichMorph] = value.val;
    })
    .onComplete(function (value) {
      //model.morphTargetInfluences[whichMorph] = startValue;
      value.val = startValue;
    });
}

export { createTween };

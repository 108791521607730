import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { createTween } from './Utils/CreateTween';
import { parseText } from './Utils/parseText';
import * as TWEEN from '@tweenjs/tween.js';
import { DoubleSide, VectorKeyframeTrack, InterpolateLinear, AnimationClip, AnimationMixer, InterpolateSmooth, LoopOnce, InterpolateDiscrete } from "three"
import * as THREE from "three"
import { store } from "../../store/store";
import { connect } from 'remx';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";


const SUBSCRIPTION_KEY = "3a5a89a72bbc4f9eba0d42a8ba80d096";
const REGION = "westus2";
const TOKEN_ENDPOINT = "https://westus2.api.cognitive.microsoft.com/sts/v1.0/issuetoken";

function Model(props) {
  

  const player = new sdk.SpeakerAudioDestination()

  const speechConfig = sdk.SpeechConfig.fromSubscription(SUBSCRIPTION_KEY, REGION);
  const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput(player);
  
  const synthesizer = new sdk.SpeechSynthesizer(speechConfig,audioConfig);


  player.onAudioStart = function(_) {
    console.log("playback started");
  }

  
    const [idlePlay, setIdlePlay] = useState(true);
    const [talkPlay, setTalkPlay] = useState(false);
    const [talkClip, setTalkClip] = useState();

    const { camera } = useThree();

    useFrame(() => {
      nodes.Sag_goz.lookAt(camera.position.x - 0.08,camera.position.y,  camera.position.z )
      nodes.Sol_goz.lookAt( camera.position.x + 0.08,camera.position.y, camera.position.z )
      nodes.Head.lookAt(camera.position)
    })

    const group = useRef()
    const body_1 = useRef()
    const { nodes, materials, animations } = useGLTF('/aiko_mixamo_v17_1024_textures.glb')
    const { actions } = useAnimations(animations, group)

    const meshForLips = nodes.body_rigsiz_1;
    const mixer = new AnimationMixer(meshForLips)
    const influences = meshForLips.morphTargetInfluences;


    

    useFrame((state, delta) => {
        TWEEN.update();
        mixer.update(delta);
      });
    
    const idleMap = {
        1: 'idle',
        2: 'idle',
        3: 'chat_stand_idle',
        4: 'chat_leaning_idle',
      };
      const talkMap = {
        1: 'standing_chat',
        2: 'stand_talk',
        3: 'chat_stand',
        4: 'chat_leaning',
        5: 'sit_to_stand',
      };

    const [randomAnimationNumber, setRandomAnimationNumber] = useState()
    //Animations
    useLayoutEffect(() => {

        for(let i=0; i<influences.length; i++){
            if(influences[i] !== 0){
                influences[i] = 0;
            }
        }


      actions[idleMap[randomAnimationNumber || '1']].clampWhenFinished = true;

      if (idlePlay && !talkPlay) {
          console.log(nodes)
        actions[idleMap[randomAnimationNumber || '1']]
          .reset()
          .setEffectiveTimeScale(1)
          .setEffectiveWeight(1)
          .fadeIn(1)
          .play();
      } else if (!idlePlay && talkPlay) {
        actions[idleMap[randomAnimationNumber || '1']].crossFadeTo(
          actions[talkMap[randomAnimationNumber || '1']],
          1,
          true
        );
        actions[talkMap[randomAnimationNumber || '1']]
          .reset()
          .setEffectiveTimeScale(1)
          .setEffectiveWeight(1)
          .fadeIn(1)
          .play();
      }

      return () => {
        actions[talkMap[randomAnimationNumber || '1']].crossFadeTo(
          actions[idleMap[randomAnimationNumber || '1']],
          1,
          true
        );
      };
    
    }, [idlePlay]);

    //Eye Blinking
    const tweenLeftEye = createTween(meshForLips, 26, 0, 1, 50);
    tweenLeftEye.easing(TWEEN.Easing.Elastic.InOut);

    const tweenRightEye = createTween(meshForLips, 27, 0, 1, 50);
    tweenRightEye.easing(TWEEN.Easing.Elastic.InOut);

    const tweenLeftEyeReset = createTween(meshForLips, 26, 1, 0, 100);
    tweenLeftEyeReset.easing(TWEEN.Easing.Elastic.InOut);

    const tweenRightEyeReset = createTween(meshForLips, 27, 1, 0, 100);
    tweenRightEyeReset.easing(TWEEN.Easing.Elastic.InOut);

    let randomNumberForBlink = 1;

    useEffect(()=>{
      setInterval(function generateRandomEyeBlink() {
        randomNumberForBlink = Math.random();
        if (randomNumberForBlink < 0.14) {
          tweenLeftEye.start().onComplete(() => {
            tweenLeftEyeReset.start();
          });
          tweenRightEye.start().onComplete(() => {
            tweenRightEyeReset.start();
          });
        }
      }, 500);
    },[])

    useLayoutEffect(()=>{

        if(talkClip){

            console.log("mixer", mixer)
            console.log("TalkClip : ", talkClip )
            
            const talkAction = mixer.clipAction(talkClip);

            console.log("Action : ", talkAction)

            talkAction.loop = LoopOnce

            talkAction.play();
        }
    }, [talkClip])

    

    useEffect(()=>{

      const subscriptionKey = "3a5a89a72bbc4f9eba0d42a8ba80d096";
      const regionOptions = "westus2"
      let authorizationToken;
      const isSsml = false;
      const SpeechSDK = window.SpeechSDK;
      let synthesizer;
      let player;
      let speechConfig;


        //US English Female
        //UK English Female
        //Turkish Female
        //window.responsiveVoice.enableEstimationTimeout = false;
        //en-US-AriaNeural
        //en-US
        //tr-TR-EmelNeural
        //tr-TR

        const xmlText = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                          xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US">
                            <voice name="en-US-AriaNeural" >
                              <mstts:express-as style="cheerful">
                                ${props.nlpResponse}
                              </mstts:express-as>
                            </voice>
                        </speak>`

      if (authorizationToken) {
        speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(authorizationToken, regionOptions.value);
      } else {
        if (subscriptionKey === "" || subscriptionKey === "subscription") {
          console.log("Please enter your Microsoft Cognitive Services Speech subscription key!");
          return;
        }
        speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, regionOptions);
      }

      speechConfig.speechSynthesisVoiceName = "Microsoft Server Speech Text to Speech Voice (en-US, AriaNeural)";
      speechConfig.speechSynthesisOutputFormat = SpeechSDK.SpeechSynthesisOutputFormat.Audio24Khz48KBitRateMonoMp3;  

      player = new SpeechSDK.SpeakerAudioDestination();
      player.onAudioStart = function(_) {
        console.log("playback started");
      }
      player.onAudioEnd = function (_) {
        console.log("playback finished");
      };
      
      var audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
  
      synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

      synthesizer.synthesisStarted = function (s, e) {
        console.log(e);
        player.pause();
      };

      const complete_cb = function (result) {
        if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
          console.log("synthesis finished")
        } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
          console.log("error")
        }
        console.log(result);
        synthesizer.close();
        synthesizer = undefined;
      };
      const err_cb = function (err) {
        console.log(err);
        synthesizer.close();
        synthesizer = undefined;
      };
      

        let visemeDataArray = [];
        let visemeAudioOffsetArray = [];
        let visemeIdArray = [];
        synthesizer.visemeReceived = function (s, e) {
          //console.log("(Viseme), Audio offset: " + e.audioOffset / 10000000 + "s. Viseme ID: " + e.visemeId);
          visemeDataArray.push(e.audioOffset / 10000)
          visemeAudioOffsetArray.push(e.audioOffset / 10000000)
          visemeIdArray.push(e.visemeId)
        }

        //viseme to morp dictionary
        const visemeToMorph = {
            0  : 11,
            1  : 16,
            2  : 10,
            3  : 9,
            4  : 11,
            5  : 15,
            6  : 16,
            7  : 0,
            8  : 10,
            9  : 10,
            10 : 10,
            11 : 16,
            12 : 8,
            13 : 14,
            14 : 12,
            15 : 9,
            16 : 9,
            17 : 8,
            18 : 13,
            19 : 9,
            20 : 12,
            21 : 11
        }

      synthesizer.synthesisCompleted = function (s, e) {

        console.log("sentezleme bittiiiiiiiiiiii");

        let timeLines = [];
        for(let i=0; i<visemeAudioOffsetArray.length; i++){
            if(i == 0){
                timeLines.push([0, visemeAudioOffsetArray[0], visemeAudioOffsetArray[0] + 0.1 ])
            }
            if(i>0){
                timeLines.push([visemeAudioOffsetArray[i - 1], visemeAudioOffsetArray[i], visemeAudioOffsetArray[i] + 0.1])
                //timeLines.push([visemeAudioOffsetArray[i - 1], visemeAudioOffsetArray[i - 1] + ((visemeAudioOffsetArray[i] - visemeAudioOffsetArray[i - 1]) / 5) , visemeAudioOffsetArray[i]])
            }
        }

        let morphsToAnimate = [];
        for(let i=0; i<visemeIdArray.length; i++){
            morphsToAnimate.push(visemeToMorph[visemeIdArray[i]])
        }

        console.log("timeLines",timeLines)
        console.log("morphsToAnimate", morphsToAnimate)

        let tracks = [];
        for(let i=0; i<visemeIdArray.length; i++){
            tracks.push(new VectorKeyframeTrack(`.morphTargetInfluences[${morphsToAnimate[i]}]`, timeLines[i], [0,1,0], InterpolateLinear))
        }


        const talkClip = new AnimationClip("talkAnimation", undefined, tracks)

        THREE.AnimationUtils.makeClipAdditive( talkClip);

        console.log("visemeAudioOffsetArray : ", visemeAudioOffsetArray)
        console.log("visemeIdArray",visemeIdArray)
        
        const talkingAnimationLenght = Math.max(...visemeDataArray)
        console.log(talkingAnimationLenght);
        if(visemeDataArray){
          setTimeout(() => {
            setRandomAnimationNumber(Math.floor(Math.random() * 4) + 1)
            console.log("animasyon bitimi");
            setIdlePlay(true);
            setTalkPlay(false);
          },talkingAnimationLenght);
        }
        console.log("sentezleme bittiiiiiiiiiiii");
        setIdlePlay(false);
        setTalkPlay(true);
        setTalkClip(prevState => {
            return talkClip
        })
        player.resume();
    };
  
    synthesizer.speakSsmlAsync(xmlText,
      complete_cb,
      err_cb);
      
    },[props.nlpResponse])



    

  
    //[-0.05, -1.57, 4.1]
    //rot [0.08, 0, 0]
    return (
        <group ref={group} {...props} dispose={null} position={[-0.05, -1.57, 3.99]}>
      <primitive object={nodes.Hips} />
      <skinnedMesh
        geometry={nodes.karakter_GRP_1.geometry}
        material={materials.Jacket_PBR}
        skeleton={nodes.karakter_GRP_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.karakter_GRP_2.geometry}
        material={materials.Hair_PBRR}
        skeleton={nodes.karakter_GRP_2.skeleton}
      />
      <skinnedMesh
        name="body_rigsiz_1"
        geometry={nodes.body_rigsiz_1.geometry}
        material={materials.Yuz_PBRR}
        skeleton={nodes.body_rigsiz_1.skeleton}
        morphTargetDictionary={nodes.body_rigsiz_1.morphTargetDictionary}
        morphTargetInfluences={nodes.body_rigsiz_1.morphTargetInfluences}
      />
      <skinnedMesh
        name="body_rigsiz_2"
        geometry={nodes.body_rigsiz_2.geometry}
        material={materials.gozler_PBR}
        skeleton={nodes.body_rigsiz_2.skeleton}
        morphTargetDictionary={nodes.body_rigsiz_2.morphTargetDictionary}
        morphTargetInfluences={nodes.body_rigsiz_2.morphTargetInfluences}
      />
      <skinnedMesh
        name="body_rigsiz_3"
        geometry={nodes.body_rigsiz_3.geometry}
        material={materials.Vucud_PBRR}
        skeleton={nodes.body_rigsiz_3.skeleton}
        morphTargetDictionary={nodes.body_rigsiz_3.morphTargetDictionary}
        morphTargetInfluences={nodes.body_rigsiz_3.morphTargetInfluences}
      />
    </group>
  )
}
useGLTF.preload('/aiko_mixamo_v17_1024_textures.glb')

function mapStateToProps(ownProps) {
  return {
    nlpResponse: store.getNlpResponse(),
  };
}

export default connect(mapStateToProps)(Model);
